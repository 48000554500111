import { Game, GameStore } from 'datamodel/game';
import { Product } from 'datamodel/product';
import { ApiResponse } from 'datamodel/response';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getGame } from '../../api/games';
import { UserContext } from '../../auth/userContext';
import Loading from '../../components/loading/Loading';
import ProductDetails from '../../components/product-details/ProductDetails';

export default function GameDetails() {
  const ctx = useContext(UserContext);
  const { store, id } = useParams<{ store: GameStore; id: string }>();
  const [game, setGame] = useState<ApiResponse<Game> | undefined>(undefined);

  useEffect(() => {
    // fetch data
    const dataFetch = async () => {
      const data = await getGame(store!, id!, ctx.credentials);
      setGame(data);
    };

    dataFetch();
  }, [ctx.credentials, id]);

  if (game?.success) {
    return <ProductDetails product={game.data as Product} />;
  } else {
    return <Loading />;
  }
}
