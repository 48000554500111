import { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

import classes from './Button.module.css';

export default function Button(
  props: PropsWithChildren<{
    steam?: boolean;
    steamMini?: boolean;
    download?: boolean;
    href?: string;
    newTab?: boolean;
  }>
) {
  const buttonClasses = `${classes.button} ${props.steam ? classes.steam : ''} ${props.steamMini ? classes.steamMini : ''}`;

  if (props.href) {
    return props.download ? (
      <a
        className={`${buttonClasses} ${classes.a}`}
        href={props.href}
        target={props.newTab ? 'blank' : ''}
      >
        {props.children}
      </a>
    ) : (
      <Link
        className={`${buttonClasses} ${classes.a}`}
        to={props.href}
        target={props.newTab ? 'blank' : ''}
      >
        {props.children}
      </Link>
    );
  } else {
    return <div className={buttonClasses}>{props.children}</div>;
  }
}
