import { Outlet, useNavigation } from 'react-router-dom';
import { Fragment } from 'react/jsx-runtime';

import UserContextProvider from '../auth/userContext';
import Loading from '../components/loading/Loading';
import Navbar from '../components/navbar/Navbar';
import classes from './Root.module.css';

export default function Root() {
  const navigation = useNavigation();
  const isLoading = navigation.state === 'loading';

  return (
    <Fragment>
      <UserContextProvider>
        <Navbar />
        <div className={classes.page}>
          <Loading finish={!isLoading} />
          <Outlet />
        </div>
      </UserContextProvider>
    </Fragment>
  );
}
