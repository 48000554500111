import {
  faCircleInfo,
  faRectangleXmark,
  faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropsWithChildren, useState } from 'react';

import classes from './Notice.module.css';

export default function Notice(
  props: PropsWithChildren<{
    warn?: boolean;
  }>
) {
  const [closed, setClosed] = useState(false);

  const onClose = () => {
    setClosed(true);
  };

  return (
    <div
      className={`${classes.notice} ${props.warn ? classes.error : ''} ${closed ? classes.closed : ''}`}
    >
      <span className={classes.closeButton} onClick={onClose}>
        <FontAwesomeIcon icon={faRectangleXmark} />
      </span>
      <span className={classes.infoIcon}>
        {props.warn ? (
          <FontAwesomeIcon icon={faTriangleExclamation} />
        ) : (
          <FontAwesomeIcon icon={faCircleInfo} />
        )}
      </span>
      <span>{props.children}</span>
    </div>
  );
}
