import { Product } from 'datamodel/product';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import animations from '../../styles/animations/animations.module.css';
import classes from './ProductPreview.module.css';

function ProductPreview(props: { product: Product; href: string }) {
  const [isLoading, setLoading] = useState(true);
  const product = props.product;
  const href = props.href;

  function onLoad() {
    setLoading(false);
  }

  return (
    <Link
      className={`${classes.productPreview} ${animations.fadeIn}`}
      to={href}
    >
      <img
        className={`${classes.productPreviewImage} ${animations.fadeIn}`}
        src={product.posterImage}
        onLoad={onLoad}
        style={{ display: isLoading ? 'none' : 'block' }}
      />
      <img
        className={`${classes.productPreviewImage} ${animations.fadeIn}`}
        src="/products/res/product-placeholder.png"
        style={{ display: isLoading ? 'block' : 'none' }}
      />
      {product.note ? (
        <span className={classes.note}>{product.note}</span>
      ) : null}
    </Link>
  );
}

export default ProductPreview;
