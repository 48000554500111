import { UserRole } from 'datamodel/user';
import { PropsWithChildren, useContext } from 'react';

import { UserContext } from '../../../auth/userContext';
import classes from './ServiceGroup.module.css';

function ServiceGroup(
  props: PropsWithChildren<{ title: string; allow?: UserRole[] }>
) {
  const title = props.title;
  const allowRoles = props.allow;

  const ctx = useContext(UserContext);
  const userRoles = ctx.userInfo?.roles;

  if (allowRoles) {
    for (let i = 0; i < allowRoles.length; ++i) {
      const allow = allowRoles[i];
      if (userRoles?.includes(allow) || userRoles?.includes('admin')) break;

      return <></>;
    }
  }

  return (
    <div>
      <h2>{title}</h2>
      <div className={classes.serviceGroup}>{props.children}</div>
    </div>
  );
}

export default ServiceGroup;
