import { useContext } from 'react';
import { useSearchParams } from 'react-router-dom';

import { getGames, searchGames } from '../../api/games';
import { UserContext } from '../../auth/userContext';
import Input from '../../components/input/Input';
import Notice from '../../components/notice/Notice';
import { ProductList } from '../../components/product-list/ProductList';
import classes from './Games.module.css';

export default function Games() {
  const ctx = useContext(UserContext);

  const [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.get('query') ?? '';

  const onNewQuery = (newQuery?: string) => {
    if (newQuery !== query) {
      if (newQuery) {
        setSearchParams({
          query: newQuery
        });
      } else {
        setSearchParams({});
      }
    }
  };

  const getGameProducts = async () => {
    const games = await getGames(ctx.credentials);
    games.data?.map((game) => {
      game.note = game.store === 'switch' ? 'Switch' : undefined;
      game.id = `${game.store}/${game.storeId}`;
      return game;
    });
    return games;
  };

  const onSearch = async (query: string) => {
    const games = await searchGames(query, ctx.credentials);
    games.data?.map((game) => {
      game.note = game.store === 'switch' ? 'Switch' : undefined;
      game.id = `${game.store}/${game.storeId}`;
      return game;
    });

    return games;
  };

  return (
    <div className={classes.games}>
      <Notice>
        Downloads are powered by torrents. Make sure you have a torrent client
        installed, like{' '}
        <a
          target="_blank"
          href="https://www.fosshub.com/qBittorrent.html?dwl=qbittorrent_4.5.2_x64_setup.exe"
          rel="noreferrer"
          className={classes.link}
        >
          qBitTorrent
        </a>
        !
        <hr />
        Games are added manually by the FIIDR administration team. Please
        contact a site admin to request a game.
      </Notice>
      <div>
        <Input
          defaultValue={query}
          onEnter={onNewQuery}
          buttonText="Search"
          showSearchButton={true}
          placeholder="Search..."
        />
      </div>
      <ProductList
        query={query}
        getProducts={getGameProducts}
        searchProducts={onSearch}
        productBaseHref={'games'}
      />
    </div>
  );
}
