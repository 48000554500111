import {
  faGamepad,
  faHouse,
  faWindowRestore
} from '@fortawesome/free-solid-svg-icons';
import { Fragment, useContext } from 'react';
import { Link } from 'react-router-dom';

import { getUserIconPath } from '../../api/users';
import { UserContext } from '../../auth/userContext';
import NavItem from './NavItem';
import classes from './Navbar.module.css';

export default function Navbar() {
  const ctx = useContext(UserContext);

  return (
    <Fragment>
      <nav className={classes.topbar}>
        <img className={classes.topbarLogo} src="/favicon.png" />
        <span>FIIDR</span>
      </nav>

      <nav className={classes.navbar}>
        <NavItem logo />
        <div className={classes.navItems}>
          <NavItem icon={faHouse} href="/">
            Home
          </NavItem>
          <NavItem icon={faGamepad} allow="games" href="/games">
            Games
          </NavItem>
          <NavItem icon={faWindowRestore} allow="apps" href="/apps">
            Apps
          </NavItem>
          <NavItem isUser href="/user" />
          {/* <NavItem icon={faClapperboard} allow="media" href="/movies">Movies</NavItem> */}
          {/* <NavItem icon={faTv} allow="media" href="/shows">Shows</NavItem> */}
        </div>
        <Link className={classes.navUser} to="/user">
          <img
            className={classes.navUserIcon}
            src={getUserIconPath(ctx.userInfo!)}
          />
        </Link>
      </nav>
    </Fragment>
  );
}
