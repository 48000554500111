import { Game, GameStore } from 'datamodel/game';
import { ApiResponse } from 'datamodel/response';

import { UserCredentials } from '../auth/userContext';
import { API_HOST, getRequestOptions } from './api';

async function getGames(creds: UserCredentials): Promise<ApiResponse<Game[]>> {
  const res = await fetch(API_HOST + '/games', getRequestOptions('GET', creds));
  return await res.json();
}

async function searchGames(
  query: string,
  credentials: UserCredentials
): Promise<ApiResponse<Game[]>> {
  const res = await fetch(
    API_HOST + `/games?query=${query}`,
    getRequestOptions('GET', credentials)
  );

  return await res.json();
}

async function getGame(
  store: GameStore,
  gameId: string,
  credentials: UserCredentials
): Promise<ApiResponse<Game>> {
  const res = await fetch(
    API_HOST + `/games/${store}/${gameId}`,
    getRequestOptions('GET', credentials)
  );

  return await res.json();
}

export { getGame, getGames, searchGames };
