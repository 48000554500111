import { IconDefinition } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserRole } from 'datamodel/user';
import { PropsWithChildren, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { getUserIconPath } from '../../api/users';
import { UserContext } from '../../auth/userContext';
import classes from './NavItem.module.css';

export default function NavItem(
  props: PropsWithChildren<{
    href?: string;
    logo?: boolean;
    icon?: IconDefinition;
    allow?: UserRole;
    isUser?: boolean;
  }>
) {
  const ctx = useContext(UserContext);

  const href = props.href ?? '';
  const isLogo = props.logo;
  const icon = props.icon;
  const allow = props.allow;
  const isUser = props.isUser;

  if (
    allow &&
    !ctx.userInfo?.roles.includes(allow) &&
    !ctx.userInfo?.roles.includes('admin')
  ) {
    return null;
  }

  if (isUser) {
    return (
      <NavLink
        className={({ isActive }) =>
          `${classes.navItem} ${classes.navItemUser} ${isActive ? classes.enabled : ''}`
        }
        to={href}
      >
        <img
          className={classes.navUserIcon}
          src={getUserIconPath(ctx.userInfo!)}
        />
      </NavLink>
    );
  }

  return isLogo ? (
    <div className={classes.logo}>
      <img src="/favicon.png" />
      <span className={classes.logoTitle}>FIIDR</span>
    </div>
  ) : (
    <NavLink
      to={href}
      className={({ isActive }) =>
        `${classes.navItem} ${isActive ? classes.enabled : ''}`
      }
    >
      {icon ? <FontAwesomeIcon icon={icon} className={classes.icon} /> : <></>}
      <span className={classes.itemText}>{props.children}</span>
    </NavLink>
  );
}
