import { useContext } from 'react';

import { getUserIconPath } from '../../api/users';
import { UserContext } from '../../auth/userContext';
import animations from '../../styles/animations/animations.module.css';
import classes from './User.module.css';

export default function User() {
  const ctx = useContext(UserContext);

  return (
    <div className={classes.user}>
      <div className={`${classes.userInfo} ${animations.fadeIn}`}>
        <img className={classes.image} src={getUserIconPath(ctx.userInfo!)} />
        <div className={classes.username}>{ctx.userInfo!.displayName}</div>
        <div
          className={classes.logout}
          onClick={async () => await ctx.logOut()}
        >
          Logout
        </div>
      </div>
    </div>
  );
}
