import { App } from 'datamodel/app';
import { Product } from 'datamodel/product';
import { ApiResponse } from 'datamodel/response';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getApp } from '../../api/apps';
import { UserContext } from '../../auth/userContext';
import Loading from '../../components/loading/Loading';
import ProductDetails from '../../components/product-details/ProductDetails';

export default function AppDetails() {
  const ctx = useContext(UserContext);
  const { id } = useParams<{ id: string }>();
  const [app, setApp] = useState<ApiResponse<App> | undefined>(undefined);

  useEffect(() => {
    // fetch data
    const dataFetch = async () => {
      const data = await getApp(id!, ctx.credentials);
      setApp(data);
    };

    dataFetch();
  }, [ctx.credentials, id]);

  if (app?.success) {
    return <ProductDetails product={app.data as Product} />;
  } else {
    return <Loading />;
  }
}
