import { Fragment } from 'react';

import Notice from '../../components/notice/Notice';
import animations from '../../styles/animations/animations.module.css';
import classes from './Home.module.css';
import ServiceGroup from './components/ServiceGroup';
import ServiceLink from './components/ServiceLink';

export default function Home() {
  return (
    <div className={classes.home}>
      <Notice>
        Welcome to FIIDR!
        <hr />• Jellyfin server address: jellyfin.fiidr.xyz
      </Notice>
      <div className={animations.fadeIn}>
        <ServiceGroup title="Games and Apps">
          <ServiceLink
            allow="games"
            title="Games"
            href="/games"
            service="store"
          />
          <ServiceLink allow="apps" title="Apps" href="/apps" service="store" />
        </ServiceGroup>
        <ServiceGroup title="Movies and Shows" allow={['jf']}>
          <ServiceLink
            allow="jf"
            title="Jellyfin Web"
            subtitle="Watch Movies and Shows"
            href="https://jellyfin.fiidr.xyz"
            service="jellyfin"
          />
          <ServiceLink
            allow="jf"
            title="Jellyseerr"
            subtitle="Request Movies and Shows"
            href="https://jellyseerr.fiidr.xyz"
            service="jellyseerr"
          />
          {/* <ServiceLink allow="mc" title="Minecraft Server" href="/external/mcmap" className="home-mc-map fade-in" /> */}
        </ServiceGroup>
        <ServiceGroup title="Admin Services" allow={['admin']}>
          <ServiceLink
            title="Sonarr"
            href="/external/sonarr"
            service="sonarr"
          />
          <ServiceLink
            title="Radarr"
            href="/external/radarr"
            service="radarr"
          />
          <ServiceLink
            title="Bazarr"
            href="/external/bazarr"
            service="bazarr"
          />
          <ServiceLink
            title="Prowlarr"
            href="/external/prowlarr"
            service="prowlarr"
          />
          <ServiceLink
            title="qBitTorrent"
            href="/external/qbit"
            service="qbit"
          />
        </ServiceGroup>
      </div>
    </div>
  );
}
