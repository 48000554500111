import { UserRole } from 'datamodel/user';
import { useContext } from 'react';
import { Link } from 'react-router-dom';

import { UserContext } from '../../../auth/userContext';
import classes from './ServiceLink.module.css';

function ServiceLink(props: {
  href: string;
  title: string;
  allow?: UserRole;
  subtitle?: string;
  service:
    | 'store'
    | 'jellyfin'
    | 'jellyseerr'
    | 'sonarr'
    | 'radarr'
    | 'bazarr'
    | 'prowlarr'
    | 'qbit';
}) {
  const href = props.href;
  const title = props.title;
  const allow = props.allow;
  const subtitle = props.subtitle;
  const service = props.service;

  const ctx = useContext(UserContext);
  const userRoles = ctx.userInfo?.roles;

  if (allow && !userRoles?.includes(allow) && !userRoles?.includes('admin')) {
    return <></>;
  }

  return (
    <Link className={`${classes.serviceLink} ${classes[service]}`} to={href}>
      <div className={`${subtitle ? classes.smallTitle : ''}`}>{title}</div>
      <div className={classes.serviceSubtitle}>{subtitle}</div>
    </Link>
  );
}

export default ServiceLink;
