import { UserInfo } from 'datamodel/user';
import {
  Context,
  PropsWithChildren,
  createContext,
  useEffect,
  useState
} from 'react';
import { useNavigate } from 'react-router-dom';

import { getAuthUser, logoutUser } from '../api/users';
import Loading from '../components/loading/Loading';

const SESSION_STORAGE_KEY = 'UserCredentials';

export type UserCredentials = {
  scheme: 'Bearer';
  token?: string;
  loginDate: Date;
};
type UserContextType = {
  credentials: UserCredentials;
  logOut: () => Promise<void>;
  userInfo?: UserInfo;
};

export const UserContext: Context<UserContextType> = createContext(
  generateContext(undefined, undefined, undefined)
);

function generateContext(
  credentials: UserCredentials = generateCredentials(),
  logOutFunction: () => Promise<void> = async () => {},
  userInfo?: UserInfo
): UserContextType {
  return {
    credentials: credentials,
    logOut: logOutFunction,
    userInfo: userInfo
  };
}

function generateCredentials(token?: string): UserCredentials {
  return {
    scheme: 'Bearer',
    token: token,
    loginDate: new Date()
  };
}

function getStoredCredentials(): UserCredentials {
  let toReturn = generateCredentials();

  const storedCredentials = localStorage.getItem(SESSION_STORAGE_KEY);
  if (storedCredentials) {
    try {
      toReturn = JSON.parse(storedCredentials);
    } catch {
      logOut(undefined);
    }
  }

  return toReturn;
}

export function storeToken(token: string) {
  const credentials = generateCredentials(token);
  localStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(credentials));
  return credentials;
}

async function logOut(credentials: UserCredentials | undefined) {
  if (credentials != undefined) {
    await logoutUser(credentials);
  }

  localStorage.removeItem(SESSION_STORAGE_KEY);
}

function getContext(
  credentials: UserCredentials,
  logoutFunction: () => Promise<void>,
  userInfo?: UserInfo
): UserContextType {
  return generateContext(credentials, logoutFunction, userInfo);
}

export default function UserContextProvider(props: PropsWithChildren) {
  const [userCredentials, setUserCredentials] = useState(
    getStoredCredentials()
  );
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState<UserInfo | undefined>(undefined);

  const logout = async () => {
    await logOut(userCredentials);
    setUserCredentials(generateCredentials());
    navigate('/login');
  };

  if (userCredentials.token == undefined) {
    logout();
  }

  const ctx = getContext(userCredentials, logout, userInfo);

  useEffect(() => {
    const getUserData = async () => {
      const response = await getAuthUser(userCredentials);

      if (!response.success) {
        await ctx.logOut();
      } else {
        setUserInfo(response.data!);
      }
    };

    getUserData();
  }, [userCredentials]);

  if (!ctx.userInfo) {
    return <Loading />;
  }

  return (
    <UserContext.Provider value={ctx}>{props.children}</UserContext.Provider>
  );
}
