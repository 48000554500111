import { useContext } from 'react';
import { useSearchParams } from 'react-router-dom';

import { getApps, searchApps } from '../../api/apps';
import { UserContext } from '../../auth/userContext';
import Input from '../../components/input/Input';
import Notice from '../../components/notice/Notice';
import { ProductList } from '../../components/product-list/ProductList';
import classes from './Apps.module.css';

export default function Apps() {
  const ctx = useContext(UserContext);

  const [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.get('query') ?? '';

  const onNewQuery = (newQuery?: string) => {
    if (newQuery !== query) {
      if (newQuery) {
        setSearchParams({
          query: newQuery
        });
      } else {
        setSearchParams({});
      }
    }
  };

  const getAppProducts = async () => {
    const apps = await getApps(ctx.credentials);
    return apps;
  };

  const onSearch = async (query: string) => {
    const apps = await searchApps(query, ctx.credentials);
    return apps;
  };

  return (
    <div className={classes.apps}>
      <Notice>
        Downloads are powered by torrents. Make sure you have a torrent client
        installed, like{' '}
        <a
          target="_blank"
          href="https://www.fosshub.com/qBittorrent.html?dwl=qbittorrent_4.5.2_x64_setup.exe"
          rel="noreferrer"
          className={classes.link}
        >
          qBitTorrent
        </a>
        !
        <hr />
        Games are added manually by the FIIDR administration team. Please
        contact a site admin to request an app.
      </Notice>
      <div>
        <Input
          defaultValue={query}
          onEnter={onNewQuery}
          buttonText="Search"
          showSearchButton={true}
          placeholder="Search..."
        />
      </div>
      <ProductList
        query={query}
        getProducts={getAppProducts}
        searchProducts={onSearch}
        productBaseHref={'apps'}
      />
    </div>
  );
}
