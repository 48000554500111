import { useState } from 'react';

import Loading from '../../components/loading/Loading';
import animations from '../../styles/animations/animations.module.css';
import classes from './External.module.css';

export default function External(props: { src: string }) {
  const src = props.src;
  const [isLoading, setLoading] = useState(true);

  function onLoad() {
    setLoading(false);
  }

  return (
    <div className={classes.frameContainer}>
      <Loading />
      <iframe
        className={`${classes.frame} ${animations.fadeIn}`}
        style={{ display: isLoading ? 'none' : 'block' }}
        onLoad={onLoad}
        src={src ?? '/'}
        allow="fullscreen"
      />
    </div>
  );
}
