import { PropsWithChildren } from 'react';
import BeatLoader from 'react-spinners/BeatLoader';

import anims from '../../styles/animations/animations.module.css';
import classes from './Loading.module.css';

function Loading(
  props: PropsWithChildren<{ finish?: boolean; simple?: boolean }>
) {
  const finish = props.finish;
  const simple = props.simple;

  return (
    <div
      className={`${simple ? classes.overlaySimple : classes.overlay} ${finish ? anims.fadeOut : anims.fadeIn}`}
    >
      <BeatLoader color="white" />
      {props.children}
    </div>
  );
}

export default Loading;
