import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { loginUser } from '../../api/users';
import { storeToken } from '../../auth/userContext';
import Loading from '../../components/loading/Loading';
import animations from '../../styles/animations/animations.module.css';
import classes from './Login.module.css';

export default function Login() {
  const userNameRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const [invalid, setInvalid] = useState(false);
  const [loggingIn, setLoggingIn] = useState(false);
  const navigate = useNavigate();

  const onFormSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleLogin();
  };

  const handleLogin = async () => {
    if (loggingIn) {
      return;
    }

    setLoggingIn(true);
    const username = userNameRef.current?.value;
    const password = passwordRef.current?.value;

    const isValidUsername =
      username !== undefined &&
      username.length != 0 &&
      username.match(/\s/) == null;
    const isValidPassword = username !== undefined && username.length != 0;

    if (isValidUsername && isValidPassword) {
      const res = await loginUser(username!, password!);
      if (res.success) {
        storeToken(res.data!.token);
        navigate('/');
        return;
      }
    }

    setLoggingIn(false);
    setInvalid(true);
  };

  const clearInvalid = () => {
    if (invalid) setInvalid(false);
  };

  return (
    <div className={`${classes.loginPage} ${animations.fadeIn}`}>
      {loggingIn ? <Loading /> : <></>}
      <div className={classes.logo}>
        <img className={classes.logoImage} src="/favicon.png" />
        <span>FIIDR</span>
      </div>

      <span className={classes.signInText}>Sign in</span>
      <div
        className={`${classes.invalidLogin} ${invalid ? '' : classes.valid}`}
      >
        Invalid username or password
      </div>
      <div>
        <form className={classes.form} onSubmit={onFormSubmit}>
          <div className={classes.inputSection}>
            <div className={classes.formText}>USERNAME</div>
            <input
              className={classes.input}
              type="text"
              ref={userNameRef}
              onInput={clearInvalid}
            ></input>
          </div>
          <div className={classes.inputSection}>
            <div className={classes.formText}>PASSWORD</div>
            <input
              className={classes.input}
              type="password"
              ref={passwordRef}
              onInput={clearInvalid}
            ></input>
          </div>
          <button
            className={classes.button}
            type="submit"
            onClick={handleLogin}
          >
            Login
          </button>
        </form>
      </div>
      <span className={classes.notice}>
        Access to FIIDR is restricted and controlled by the administrator and
        can be revoked at any time without prior warning. Please use this
        website responsibly. All contents are property of their respective
        owners.{' '}
      </span>
    </div>
  );
}
