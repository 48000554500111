import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import RequiresRole from './auth/RequiresRole';
import Root from './pages/Root';
import AppDetails from './pages/apps/AppDetails';
import Apps from './pages/apps/Apps';
import External from './pages/external/External';
import GameDetails from './pages/games/GameDetails';
import Games from './pages/games/Games';
import Home from './pages/home/Home';
import Login from './pages/login/Login';
import User from './pages/user/User';

const router = createBrowserRouter([
  { path: 'login', element: <Login /> },
  {
    path: '/',
    element: <Root />,
    errorElement: <></>,
    children: [
      { path: '', element: <Home /> },
      {
        path: 'apps',
        element: <RequiresRole role="apps" />,
        children: [
          { path: '', element: <Apps /> },
          { path: ':id', element: <AppDetails /> }
        ]
      },
      {
        path: 'games',
        element: <RequiresRole role="games" />,
        children: [
          { path: '', element: <Games /> },
          {
            path: ':store/:id',
            element: <GameDetails />
          }
        ]
      },
      { path: 'user', element: <User /> },
      {
        path: 'external/sonarr',
        element: <External src="https://sonarr.fiidr.xyz" />
      },
      {
        path: 'external/radarr',
        element: <External src="https://radarr.fiidr.xyz" />
      },
      {
        path: 'external/bazarr',
        element: <External src="https://bazarr.fiidr.xyz" />
      },
      {
        path: 'external/prowlarr',
        element: <External src="https://prowlarr.fiidr.xyz" />
      },
      {
        path: 'external/qbit',
        element: <External src="https://qbit.fiidr.xyz" />
      }
    ]
  }
]);

export default function App() {
  return <RouterProvider router={router} />;
}
