import { App } from 'datamodel/app';
import { ApiResponse } from 'datamodel/response';

import { UserCredentials } from '../auth/userContext';
import { API_HOST, getRequestOptions } from './api';

async function getApps(creds: UserCredentials) {
  const res = await fetch(API_HOST + '/apps', getRequestOptions('GET', creds));
  return await res.json();
}

async function searchApps(
  query: string,
  credentials: UserCredentials
): Promise<ApiResponse<App[]>> {
  const res = await fetch(
    API_HOST + `/apps?query=${query}`,
    getRequestOptions('GET', credentials)
  );

  return await res.json();
}

async function getApp(
  appId: string,
  credentials: UserCredentials
): Promise<ApiResponse<App>> {
  const res = await fetch(
    API_HOST + `/apps/${appId}`,
    getRequestOptions('GET', credentials)
  );
  return await res.json();
}

export { getApp, searchApps, getApps };
