import { ApiResponse } from 'datamodel/response';
import { UserInfo, UserInfoWithToken } from 'datamodel/user';

import { UserCredentials } from '../auth/userContext';
import { API_HOST, getRequestOptions } from './api';

async function getAuthUser(
  credentials: UserCredentials
): Promise<ApiResponse<UserInfo>> {
  const res = await fetch(
    API_HOST + '/auth/user',
    getRequestOptions('GET', credentials)
  );
  return await res.json();
}

async function loginUser(
  username: string,
  password: string
): Promise<ApiResponse<UserInfoWithToken>> {
  const resp = await fetch(API_HOST + '/auth/login', {
    method: 'POST',
    headers: {
      //'Content-Type': 'application/json'
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: JSON.stringify({ username: username, password: password })
  });

  return await resp.json();
}

async function logoutUser(
  credentials: UserCredentials
): Promise<ApiResponse<null>> {
  const res = await fetch(
    API_HOST + '/auth/logout',
    getRequestOptions('POST', credentials)
  );
  return await res.json();
}

function getUserIconPath(userInfo: UserInfo) {
  return API_HOST + userInfo.icon;
}

export { loginUser, logoutUser, getAuthUser, getUserIconPath };
