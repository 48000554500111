import { UserRole } from 'datamodel/user';
import { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { UserContext } from './userContext';

export default function RequiresRole(props: { role: UserRole }) {
  const ctx = useContext(UserContext);
  const hasRole =
    ctx.userInfo?.roles.includes(props.role) ||
    ctx.userInfo?.roles.includes('admin');

  if (!hasRole) {
    return <Navigate to="/" />;
  }

  return <Outlet />;
}
