import { useRef } from 'react';

import classes from './Input.module.css';

export default function Input(props: {
  onEnter: (value?: string) => void;
  defaultValue?: string;
  placeholder?: string;
  showSearchButton?: boolean;
  buttonText?: string;
}) {
  const searchRef = useRef<HTMLInputElement>(null);
  const value = props.defaultValue;

  const onFormSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSearch();
  };

  const handleSearch = async () => {
    let search = searchRef.current?.value;
    if (search) {
      search = search.trim();
    }
    props.onEnter(search);
  };

  return (
    <form
      className={classes.inputForm}
      onSubmit={onFormSubmit}
      autoComplete="off"
    >
      <input
        defaultValue={value}
        className={classes.input}
        autoComplete="off"
        type="text"
        placeholder={props.placeholder}
        ref={searchRef}
      ></input>
      {props.showSearchButton ? (
        <button type="submit" className={classes.button} onClick={handleSearch}>
          {props.buttonText}
        </button>
      ) : (
        <></>
      )}
    </form>
  );
}
