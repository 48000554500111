import { Game } from 'datamodel/game';
import { Product, ProductType } from 'datamodel/product';
import { ApiResponse } from 'datamodel/response';
import { useEffect, useState } from 'react';

import Loading from '../loading/Loading';
import Notice from '../notice/Notice';
import classes from './ProductList.module.css';
import ProductPreview from './ProductPreview';

export function ProductList(props: {
  query: string;
  productBaseHref: ProductType;
  getProducts: () => Promise<ApiResponse<Product[]>>;
  searchProducts: (query: string) => Promise<ApiResponse<Product[]>>;
}) {
  const query = props.query;
  const getProducts = props.getProducts;
  const searchProducts = props.searchProducts;
  const productBaseHref = props.productBaseHref;

  const [products, setProducts] = useState<ApiResponse<Product[]> | undefined>(
    undefined
  );

  useEffect(() => {
    let isCancelled = false;
    setProducts(undefined);

    const dataFetch = async () => {
      let data;
      if (!query || query === '') {
        data = await getProducts();
      } else {
        data = await searchProducts(query);
      }

      if (isCancelled) return;
      setProducts(data);
    };

    dataFetch();

    return () => {
      isCancelled = true;
    };
  }, [productBaseHref, query]);

  if (products != undefined && products.errorMessage != undefined) {
    return (
      <div className={classes.productsList}>
        <div className={classes.products} style={{ display: 'block' }}>
          <Notice warn>{products.errorMessage}</Notice>
        </div>
      </div>
    );
  }

  if (products != undefined && products.data?.length === 0) {
    return <h1>No Results Found</h1>;
  }

  if (products != undefined) {
    return (
      <div className={classes.productsList}>
        <div className={classes.products}>
          {products.data?.map((product: Product) => (
            <ProductPreview
              key={product.id}
              product={product}
              href={`/${productBaseHref}/${product.id}`}
            />
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className={classes.productsList}>
      <div className={classes.products}>
        <Loading simple />
      </div>
    </div>
  );
}
