import { UserCredentials } from '../auth/userContext';

const API_HOST = String(process.env.REACT_APP_FIIDR_API_HOST).trim();

function getRequestOptions(
  method: 'GET' | 'POST',
  credentials: UserCredentials,
  body?: object
): RequestInit {
  return {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `${credentials?.scheme} ${credentials?.token}`
    },
    body: body && JSON.stringify(body)
  };
}

export { API_HOST, getRequestOptions };
