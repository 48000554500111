import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Product } from 'datamodel/product';

import animations from '../../styles/animations/animations.module.css';
import Button from '../button/Button';
import Notice from '../notice/Notice';
import classes from './ProductDetails.module.css';

export default function ProductDetails(props: { product: Product }) {
  const product = props.product;

  const productStyle = {
    '--product-background': `url(${product?.backgroundImage})`
  } as React.CSSProperties;

  return (
    <div
      style={productStyle}
      className={`${classes.productDetails} ${animations.fadeIn}`}
    >
      <div className={classes.productHeader}>
        <div className={classes.productInfo}>
          <h1 className={classes.productDetailsMetadataTitle}>
            {product.title}
          </h1>
          {product.downloadUri ? (
            <Button download steam href={product.downloadUri}>
              <FontAwesomeIcon
                className={classes.productDetailsDownloadIcon}
                icon={faDownload}
              />
              Download
            </Button>
          ) : (
            <Button download steam>
              Not Available
            </Button>
          )}
        </div>
      </div>
      <div className={classes.productInfoSection}>
        {product.note ? <Notice>{product.note}</Notice> : <></>}
        <div className={classes.productLinks}>
          {product.storePage ? (
            <Button steamMini href={product.storePage} newTab>
              Store Page
            </Button>
          ) : null}
          {product.website ? (
            <Button steamMini href={product.website} newTab>
              Website
            </Button>
          ) : null}
        </div>
        <p className={classes.productDetailsMetadataDescription}>
          {product.description}
        </p>
      </div>
    </div>
  );
}
